import React, {Component, KeyboardEvent} from "react"
import styles from "./input.module.css"

interface Props {
    placeholder?: string
    onChange: (value: string) => void
    disabled?: boolean
    value: string
    type?: "text" | "email" | "tel" | "date" | "password"
    onKeyDown?: (event: KeyboardEvent<Element>) => void
    onKeyPress?: (event: KeyboardEvent<Element>) => void
    onKeyUp?: (event: KeyboardEvent<Element>) => void
    onBlur?: (event: any) => void
    onFocus?: (event: any) => void
}

export class Input extends Component<Props, {}> {
    private onChange(event) {
        this.props.onChange(event.target.value)
    }

    public render() {
        const {
            placeholder,
            disabled,
            value,
            onKeyDown,
            onKeyPress,
            onKeyUp,
            onBlur,
            onFocus,
            type = "text"
        } = this.props
        return (
            <input
                className={styles.input}
                type={type}
                value={value}
                onChange={this.onChange.bind(this)}
                placeholder={placeholder}
                disabled={disabled}
                onKeyDown={onKeyDown}
                onKeyPress={onKeyPress}
                onKeyUp={onKeyUp}
                onBlur={onBlur}
                onFocus={onFocus}
                autoComplete="off"
            />
        )
    }
}
